html.has-scroll-smooth {
  overflow: hidden;
}
html.has-scroll-dragging {
  user-select: none;
}

.has-scroll-smooth body {
  overflow: hidden;
}

.has-scroll-smooth [data-scroll-container] {
  min-height: 100vh;
}
[data-scroll-direction=horizontal] [data-scroll-container] {
  height: 100vh;
  display: inline-block;
  white-space: nowrap;
}

[data-scroll-direction=horizontal] [data-scroll-section] {
  display: inline-block;
  vertical-align: top;
  white-space: nowrap;
  height: 100%;
}

.c-scrollbar {
  position: absolute;
  right: 0;
  top: 0;
  width: 11px;
  height: 100%;
  transform-origin: center right;
  transition: transform 0.3s, opacity 0.3s;
  opacity: 0;
}
.c-scrollbar:hover {
  transform: scaleX(1.45);
}
.c-scrollbar:hover, .has-scroll-scrolling .c-scrollbar, .has-scroll-dragging .c-scrollbar {
  opacity: 1;
}
[data-scroll-direction=horizontal] .c-scrollbar {
  width: 100%;
  height: 10px;
  top: auto;
  bottom: 0;
  transform: scaleY(1);
}
[data-scroll-direction=horizontal] .c-scrollbar:hover {
  transform: scaleY(1.3);
}

.c-scrollbar_thumb {
  position: absolute;
  top: 0;
  right: 0;
  background-color: black;
  opacity: 0.5;
  width: 7px;
  border-radius: 10px;
  margin: 2px;
  cursor: grab;
}
.has-scroll-dragging .c-scrollbar_thumb {
  cursor: grabbing;
}
[data-scroll-direction=horizontal] .c-scrollbar_thumb {
  right: auto;
  bottom: 0;
}

/* open-sans-300 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 300;
  src: url("../fonts/open-sans-v34-latin-300.woff2") format("woff2"), url("../fonts/open-sans-v34-latin-300.woff") format("woff"); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* open-sans-regular - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/open-sans-v34-latin-regular.woff2") format("woff2"), url("../fonts/open-sans-v34-latin-regular.woff") format("woff"); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* open-sans-600 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  src: url("../fonts/open-sans-v34-latin-600.woff2") format("woff2"), url("../fonts/open-sans-v34-latin-600.woff") format("woff"); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* open-sans-700 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  src: url("../fonts/open-sans-v34-latin-700.woff2") format("woff2"), url("../fonts/open-sans-v34-latin-700.woff") format("woff"); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* open-sans-800 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 800;
  src: url("../fonts/open-sans-v34-latin-800.woff2") format("woff2"), url("../fonts/open-sans-v34-latin-800.woff") format("woff"); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
h1, h2, h3, h4, h5, h6 {
  font-weight: 700;
}

:root {
  --vh: 1vh;
}

[unresolved] {
  display: none;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0) !important;
  -webkit-tap-highlight-color: transparent !important;
  list-style-type: none;
}

html {
  height: 100%;
}

body {
  font-family: "Open Sans", sans-serif;
  font-size: 1rem;
  font-weight: 300;
  height: 100%;
  -webkit-font-smoothing: antialiased;
  background: #000000;
  color: hsl(0deg, 0%, 93%);
  overflow-x: hidden;
}

img {
  width: 100%;
  height: auto;
  display: block;
}

a {
  text-decoration: none;
  color: hsl(0deg, 0%, 93%);
}
a:hover {
  color: #EB9B38;
}

.c-scrollbar .c-scrollbar_thumb {
  position: absolute;
  top: 0;
  right: 0;
  background-color: #663d0a;
  opacity: 1;
  width: 2px;
  border-radius: 10px;
  margin: 2px;
  cursor: grab;
}

.link-primary {
  position: relative;
  color: #EB9B38;
  font-size: 0.8125rem;
  font-weight: 400;
  letter-spacing: 1px;
  padding-bottom: 0.25rem;
  text-transform: uppercase;
}
.link-primary:hover {
  color: #f9e1c3;
}

button {
  border: none;
  background-color: transparent;
}

.btn {
  background: transparent;
  letter-spacing: 1px;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 2px;
  font-weight: bold;
  text-transform: uppercase;
  vertical-align: middle;
  text-decoration: none;
}
.btn:focused {
  outline: none;
}

.btn {
  background: rgb(199, 199, 199);
  margin: 4rem;
}

.nav-mobile {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 70px;
  z-index: 900;
  display: flex;
  justify-content: center;
  align-items: center;
}
.nav-mobile:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 70px;
  background: rgb(0, 0, 0);
  background: linear-gradient(180deg, rgb(0, 0, 0) 0%, rgba(0, 0, 0, 0) 100%);
}
.nav-mobile .hamburger {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 3;
}
.nav-mobile .brand {
  position: relative;
  z-index: 3;
}
@media (min-width: 1024px) {
  .nav-mobile {
    display: none;
  }
}

.sidenav {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  left: -100%;
  background: hsl(33deg, 12%, 4%);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  padding-top: 100px;
  z-index: 2000;
  overflow: hidden;
}
.sidenav .close-menu {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 3;
}
.sidenav .brand-sidenav {
  position: relative;
  margin-bottom: 4rem;
  z-index: 1;
}
.sidenav .navlinks {
  position: relative;
  z-index: 1;
}
.sidenav .navlinks li:not(:last-of-type) {
  margin-bottom: 1rem;
}
.sidenav .navlinks li .link {
  font-size: calc(0.625vw + 18px);
  text-transform: uppercase;
  font-weight: 400;
}
.sidenav .navlinks li .link-mobile-active {
  color: #EB9B38 !important;
}
.sidenav .sidenav-bg {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  background-color: hsl(33deg, 12%, 4%);
}
.sidenav .sidenav-bg img {
  mix-blend-mode: difference;
}
.sidenav .sidenav-anim {
  transform: translateY(20px);
  visibility: hidden;
}

.nav-desktop {
  display: none;
}
@media (min-width: 1024px) {
  .nav-desktop {
    position: fixed;
    top: 0;
    left: 50%;
    width: 100%;
    transform: translateX(-50%);
    background-color: hsla(0deg, 0%, 7%, 0.001);
    padding: 0 2rem;
    max-width: 1920px;
    height: 190px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    z-index: 800;
  }
}
@media (min-width: 1024px) and (min-width: 768px) {
  .nav-desktop {
    padding: 0 4rem;
  }
}
@media (min-width: 1024px) and (min-width: 1024px) {
  .nav-desktop {
    padding: 0 3rem;
  }
}
@media (min-width: 1024px) and (min-width: 1200px) {
  .nav-desktop {
    padding: 0 5rem;
  }
}
@media (min-width: 1024px) and (min-width: 1400px) {
  .nav-desktop {
    padding: 0 6rem;
  }
}
@media (min-width: 1024px) and (min-width: 1920px) {
  .nav-desktop {
    padding: 0 200px;
  }
}
@media (min-width: 1024px) and (min-width: 1921px) {
  .nav-desktop {
    padding: 0 244px;
  }
}
@media (min-width: 1024px) {
  .nav-desktop:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 190px;
    background: rgb(0, 0, 0);
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.85) 0%, rgba(0, 0, 0, 0) 100%);
  }
}
.nav-desktop .brand {
  position: relative;
  z-index: 1;
}
.nav-desktop .navlinks {
  position: relative;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}
.nav-desktop .navlinks li {
  margin-right: 1rem;
}
.nav-desktop .navlinks li:last-child {
  margin-right: 0;
}
.nav-desktop .navlinks li .link {
  text-transform: uppercase;
  font-size: calc(0.25vw + 9.2px);
  font-weight: 600;
  letter-spacing: 1px;
  transition: color 0.35s;
}
.nav-desktop .navlinks li .link:hover {
  color: #EB9B38;
}
.nav-desktop .navlinks li .link-active {
  position: relative;
}
.nav-desktop .navlinks li .link-active:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 1px;
  background-color: #EB9B38;
}

header {
  position: relative;
  border-bottom: solid 5px #EB9B38;
}
header .image {
  position: relative;
  z-index: 0;
}
header .image:after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: calc(7.5vw + 56px);
  background: linear-gradient(to bottom, rgba(11, 10, 9, 0) 0%, rgba(11, 10, 9, 0.85) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
}
header .content {
  position: absolute;
  bottom: 1rem;
  left: 0.5rem;
  z-index: 1;
  padding: 0 2rem;
}
@media (min-width: 768px) {
  header .content {
    padding: 0 4rem;
  }
}
@media (min-width: 1024px) {
  header .content {
    padding: 0 3rem;
  }
}
@media (min-width: 1200px) {
  header .content {
    padding: 0 5rem;
  }
}
@media (min-width: 1400px) {
  header .content {
    padding: 0 6rem;
  }
}
@media (min-width: 1920px) {
  header .content {
    padding: 0 200px;
  }
}
@media (min-width: 1921px) {
  header .content {
    padding: 0 244px;
  }
}
header .content .header-headline {
  font-size: calc(1.875vw + 14px);
  font-weight: 700;
  line-height: 1;
  text-transform: uppercase;
}
header .content .header-subline {
  font-size: calc(0.6875vw + 11.8px);
  line-height: 1;
}

footer {
  width: 100%;
  color: rgb(161, 161, 161);
}
footer .footer-border {
  margin: 0 2rem;
  padding-top: 3rem !important;
  height: 1px;
  border-top: solid 1px #EB9B38;
}
@media (min-width: 768px) {
  footer .footer-border {
    margin: 0 4rem;
  }
}
@media (min-width: 1024px) {
  footer .footer-border {
    margin: 0 3rem;
  }
}
@media (min-width: 1200px) {
  footer .footer-border {
    margin: 0 5rem;
  }
}
@media (min-width: 1400px) {
  footer .footer-border {
    margin: 0 6rem;
  }
}
@media (min-width: 1920px) {
  footer .footer-border {
    margin: 0 200px;
  }
}
@media (min-width: 1921px) {
  footer .footer-border {
    margin: 0 244px;
  }
}
footer .footer-blocks {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(min(180px, 100%), 1fr));
  grid-gap: calc(1.75vw + 26.4px);
  padding: 0 2rem;
}
@media (min-width: 768px) {
  footer .footer-blocks {
    padding: 0 4rem;
  }
}
@media (min-width: 1024px) {
  footer .footer-blocks {
    padding: 0 3rem;
  }
}
@media (min-width: 1200px) {
  footer .footer-blocks {
    padding: 0 5rem;
  }
}
@media (min-width: 1400px) {
  footer .footer-blocks {
    padding: 0 6rem;
  }
}
@media (min-width: 1920px) {
  footer .footer-blocks {
    padding: 0 200px;
  }
}
@media (min-width: 1921px) {
  footer .footer-blocks {
    padding: 0 244px;
  }
}
footer .footer-blocks .footer-block {
  text-align: center;
}
footer .footer-blocks .footer-block ul {
  display: inline-block;
  margin: 0 auto;
}
@media (min-width: 768px) {
  footer .footer-blocks .footer-block ul {
    text-align: left;
  }
}
footer .footer-blocks .footer-block ul .impressum {
  margin-top: 1rem;
}
footer .logo-footer {
  max-width: 60%;
  margin: 0 auto;
}
footer .company-info-footer .cp-name {
  font-weight: bold;
  margin-bottom: 1rem;
}
footer .company-info-footer .cp-city {
  margin-bottom: 1rem;
}
footer .company-info-footer .cp-mail {
  margin-top: 1rem;
}
footer .footer-terms {
  margin-top: 2rem;
}
footer a {
  color: rgb(161, 161, 161);
  transition: color 0.35s;
}
footer .footer-bottom {
  margin-top: 3rem;
  padding-bottom: 2rem;
  text-align: center;
  grid-gap: 0.25rem;
  font-weight: 600;
  text-transform: uppercase;
  color: #555555;
}
footer .footer-bottom a {
  color: #555555;
}
footer .footer-bottom a:hover {
  color: #EB9B38;
}
@media (min-width: 1024px) {
  footer .footer-bottom {
    display: flex;
    justify-content: center;
  }
}
footer .footer-bottom .footer-copy,
footer .footer-bottom .footer-creator {
  font-size: 12px;
}
@media (min-width: 1024px) {
  footer .footer-bottom .footer-copy,
  footer .footer-bottom .footer-creator {
    text-align: left;
  }
}
footer .footer-bottom .footer-copy {
  margin-bottom: 0.25rem;
}
@media (min-width: 1024px) {
  footer .footer-bottom .footer-copy {
    margin-bottom: 0;
  }
}
footer .footer-bottom .footer-creator span {
  margin-right: 0.25rem;
}

.ovh {
  overflow: hidden;
}

.umbau {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.umbau svg {
  max-width: 500px;
  margin-bottom: 4rem;
}

.wrapper {
  max-width: 1920px;
  margin: 0 auto;
  background: hsl(33deg, 12%, 4%);
}

section {
  margin-top: 6rem;
  margin-bottom: 10rem;
  padding: 0 2rem;
}
@media (min-width: 768px) {
  section {
    padding: 0 4rem;
  }
}
@media (min-width: 1024px) {
  section {
    padding: 0 3rem;
  }
}
@media (min-width: 1200px) {
  section {
    padding: 0 5rem;
  }
}
@media (min-width: 1400px) {
  section {
    padding: 0 6rem;
  }
}
@media (min-width: 1920px) {
  section {
    padding: 0 200px;
  }
}
@media (min-width: 1921px) {
  section {
    padding: 0 244px;
  }
}
section .section-headline {
  color: #EB9B38;
  font-weight: 600;
  font-size: calc(0.3125vw + 19px);
  padding: 0;
  width: 100%;
  display: flex;
  align-items: flex-end;
  margin-bottom: calc(1vw + 12.8px);
}
section .section-headline .section-title {
  line-height: 0.8;
  margin-right: 1rem;
  min-width: 0;
  flex-shrink: 0;
}
section .section-headline .stripe {
  width: 100%;
  height: calc(0.0625vw + 0.8px);
  background: #EB9B38;
}
section .headline {
  font-size: calc(0.125vw + 17.6px);
  color: #EB9B38;
  font-weight: 400;
  margin-bottom: calc(0.25vw + 15.2px);
}
section .text {
  font-size: calc(0vw + 14px);
  line-height: 1.618;
}

.cards {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(min(200px, 100%), 1fr));
  grid-gap: calc(1.75vw + 26.4px);
  margin: 0 auto;
}
.cards .card {
  display: flex;
  flex-direction: column;
  margin-bottom: calc(1.875vw + 44px);
}
.cards .card .image {
  aspect-ratio: 16/9;
  overflow: hidden;
  border-radius: 4px;
  margin-bottom: calc(0vw + 32px);
}
.cards .card .image img {
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.cards .card .card-content {
  margin-bottom: 2rem;
  flex-grow: 2;
}
.conic {
  /* Created with https://www.css-gradient.com */
  background: conic-gradient(from 315deg, #010101, #ffffff);
}

.section-welcome .section-content p {
  font-size: calc(0.125vw + 15.6px);
  margin-bottom: 1rem;
}

.section-investition {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(min(380px, 100%), 1fr));
  grid-gap: calc(1.75vw + 26.4px);
  margin: 0 auto;
  margin-top: 3rem;
  margin-bottom: 6rem;
}
.section-investition .content p {
  margin-bottom: 1rem;
}
.section-investition .content .blue {
  color: #62BCF7;
  font-weight: 600;
}
.section-investition .content a {
  color: #62BCF7;
  font-weight: 600;
}
.section-investition .image {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
  max-width: 600px;
  margin: 0 auto;
}

.section-news .cards {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(min(350px, 100%), 1fr));
  grid-gap: calc(1.75vw + 26.4px);
  margin: 0 auto;
}
.section-news .cards .card .date {
  font-size: 0.8125rem;
  color: rgb(161, 161, 161);
}
.section-news .cards .card .content {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
@supports (-webkit-line-clamp: 5) {
  .section-news .cards .card .content {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: initial;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
  }
}

.section-news .cards .card .date {
  font-size: 0.8125rem;
}
.section-news .cards .card .content {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
@supports (-webkit-line-clamp: 5) {
  .section-news .cards .card .content {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: initial;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
  }
}

.section-news-detail .news-single-wrapper .card {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(min(380px, 100%), 1fr));
  grid-gap: calc(1.75vw + 26.4px);
  margin: 0 auto;
}
.section-news-detail .news-single-wrapper .card .image {
  aspect-ratio: 16/9;
  overflow: hidden;
  border-radius: 4px;
  margin-bottom: calc(0vw + 32px);
}
.section-news-detail .news-single-wrapper .card .image img {
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.section-material .cards {
  grid-template-columns: repeat(auto-fit, minmax(min(400px, 100%), 1fr));
  grid-gap: calc(1.75vw + 26.4px);
}
.section-material .cards .card {
  white-space: normal;
}
.section-material .cards .card .card-title {
  background-color: #EB9B38;
  color: hsl(33deg, 12%, 4%);
  font-size: calc(0.125vw + 15.6px);
  font-weight: 600;
  padding: 0.25rem 1rem 0.25rem 0.5rem;
  margin-bottom: 1rem;
}
.section-material .cards .card .material-list li {
  position: relative;
  padding-left: 1rem;
}
.section-material .cards .card .material-list li:before {
  content: "";
  position: absolute;
  top: 8px;
  left: 0;
  width: 4px;
  height: 4px;
  background-color: #EB9B38;
}

.section-who-is .cards {
  grid-template-columns: repeat(auto-fit, minmax(min(400px, 100%), 1fr));
}
.section-who-is .cards .card .card-image {
  margin-bottom: 1rem;
}
.section-who-is .cards .card .card-image img {
  border-radius: 4px;
  max-width: 336px;
}

.section-links .links {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(min(380px, 100%), 1fr));
  grid-gap: calc(1.75vw + 26.4px);
  margin: 0 auto;
}
.section-links .links .link-name {
  font-size: calc(0.125vw + 17.6px);
  font-weight: 600;
}
.section-links .links .link-description {
  margin-bottom: 0.25rem;
}
.section-links .links .link-url a {
  color: #EB9B38;
}

.section-gebrauchtes table {
  width: 100%;
}

.section-kontakt .section-content ul li:first-child {
  font-size: calc(0.25vw + 17.2px);
  font-weight: 600;
  margin-bottom: 1rem;
}
.section-kontakt .section-content ul .mb1 {
  margin-bottom: 1rem;
}

.section-terms .section-content div {
  margin-bottom: 2rem;
}
.section-terms .section-content .bold {
  font-weight: 700;
  margin-bottom: 0.5rem;
}
